

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

table {
  border: 1px solid #ddd;  
}

td {
  padding-right: 35px;
  padding-left: 15px;
}

@media (max-width: 475px) {
  .add100 {
    width: 100%;
    overflow-x: auto;
  }
}

@media (min-width: 476px) {
  .add100 {
    overflow-x: auto;
  }
}